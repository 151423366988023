.px-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.py-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.form-check-input {
  border: 1px solid #333333 !important;
}

.me-3px {
  margin-right: 3px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

img.img-remove {
  cursor: pointer;
  opacity: 0;
}
img.img-remove:hover {
  opacity: 1;
}

.w-100px {
  width: '100px !important';
}

.ReactTable .rt-resizable-header {
  padding: 6px 24px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ReactTable .rt-resizer:before {
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 3px;
  height: 18px;
  width: 18px;
  color: transparent;
  content: '.';
  background-size: 18px 18px;
  background-repeat: no-repeat; 
  opacity: 0.87;
}

.ReactTable  .rt-resizable-header-content:after {
  position: absolute;
  right: 8px;
  top: 3px;
  height: 18px;
  width: 18px;
  z-index: 120;
  color: transparent;
  content: '.';
}

// .table > tbody > tr > td {
//   vertical-align: middle;
// }

.flex-even {
  flex: 1;
}

.h-line {
  font-size: 22px;
  fonr-weight: semibold;
  color: var(--ct-border-color);
  width: 60%; 
  text-align: center; 
  border-bottom: 2px dotted var(--ct-border-color); 
  line-height: 0px;
  margin: 10px 0 20px; 
} 

.h-line span { 
   background:#fff; 
   padding:0 10px; 
}


/* custom scrollbar */
// ::-webkit-scrollbar {
//   width: 20px;
// }

// ::-webkit-scrollbar-track {
//   background-color: transparent;
// }

// ::-webkit-scrollbar-thumb {
//   background-color: #d9dbe0;
//   border-radius: 20px;
//   border: 4px solid transparent;
//   background-clip: content-box;
// }

// ::-webkit-scrollbar-thumb:hover {
//   background-color: #d2d4da;
// }

html[data-layout=horizontal] .menu, html[data-layout=horizontal] .topbar, html[data-layout=horizontal] .content, html[data-layout=horizontal] footer > * {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    // max-width: 100vw !important;
  } 
}

.form-control::-webkit-input-placeholder { color: #aab0b9 !important; }
.form-control:-moz-placeholder { color: #aab0b9 !important; }
.form-control::-moz-placeholder { color: #aab0b9 !important; }
.form-control:-ms-input-placeholder { color: #aab0b9 !important; }

.max-w-300px {
  max-width: 300px;
}

.form-label {
  margin-bottom: 0.3rem !important;
}

// Hide blink cursor react datepicker
.react-datepicker-input {
  color: transparent !important;
  text-shadow: 0 0 0 #6c757d !important;

  &:focus {
      outline: none !important;
  }
}